import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Projets.module.css";

const Projets = () => {
  const navigate = useNavigate();

  const onProjetsContainerClick = useCallback(() => {
    navigate("/galeries");
  }, [navigate]);

  return (
    <div className={styles.projets} onClick={onProjetsContainerClick}>
      <b className={styles.projets1}>Projets</b>
      <main className={styles.projetContainerWrapper}>
        <section className={styles.projetContainer}>
          <div className={styles.projet1}>
            <b className={styles.esiau}>ESIAU</b>
            <b className={styles.b}>1</b>
          </div>
          <div className={styles.projet2}>
            <b className={styles.robotsmali}>RobotsMali</b>
            <b className={styles.b1}>2</b>
          </div>
          <div className={styles.projet3}>
            <b className={styles.parc}>PARC</b>
            <b className={styles.b2}>3</b>
          </div>
          <div className={styles.projet4}>
            <b className={styles.bangalor}>Bangalor</b>
            <b className={styles.b3}>4</b>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Projets;
