import { useCallback } from "react";
import { Button } from "@mui/material";
import styles from "./Contacts.module.css";

const Contacts = () => {
  const onChaneYoutubeTextClick = useCallback(() => {
    window.open("https://youtube.com");
  }, []);

  const onSiteOfficielRobotsMaliClick = useCallback(() => {
    window.open("https://robotsmali.org");
  }, []);

  const onCtaContainerClick = useCallback(() => {
    window.location.href = "mailto:malick@gmail.com";
  }, []);

  return (
    <div className={styles.contacts}>
      <div className={styles.contactContainer}>
        <div className={styles.lienUtileWrapper}>
          <h3 className={styles.lienUtile}>Lien utile</h3>
        </div>
        <div className={styles.siteMalicksEyesParent}>
          <b className={styles.siteMalicksEyes}>Site malick’s eyes</b>
          <b className={styles.chaneYoutube} onClick={onChaneYoutubeTextClick}>
            Chaîne Youtube
          </b>
        </div>
        <b
          className={styles.siteOfficielRobotsmali}
          onClick={onSiteOfficielRobotsMaliClick}
        >
          Site officiel RobotsMali
        </b>
      </div>
      <div className={styles.followingArea}>
        <div className={styles.mediaContainer}>
          <div className={styles.mediaContainerInner}>
            <div className={styles.laisseNousUnMessageParent}>
              <h3 className={styles.laisseNousUnMessage}>
                Laisse-nous un message
              </h3>
              <div className={styles.frameWrapper}>
                <div className={styles.messageParent}>
                  <Button
                    className={styles.message}
                    disableElevation={true}
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      color: "#000",
                      fontSize: "14",
                      background: "#f4eee2",
                      border: "#fff solid 2px",
                      borderRadius: "5px",
                      "&:hover": { background: "#f4eee2" },
                      height: 27,
                    }}
                  >
                    Votre email
                  </Button>
                  <div className={styles.cta} onClick={onCtaContainerClick}>
                    <b className={styles.ecrire}>ECRIRE</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.following}>
            <img className={styles.mediaIcon} alt="" src="/media@2x.png" />
            <h3 className={styles.suivezNous}>Suivez-nous</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
