import { useMemo } from "react";
import styles from "./Tuto1.module.css";

const Tuto1 = ({ prop, propWidth, propAlignSelf }) => {
  const tuto3Style = useMemo(() => {
    return {
      width: propWidth,
      alignSelf: propAlignSelf,
    };
  }, [propWidth, propAlignSelf]);

  return (
    <div className={styles.tuto3} style={tuto3Style}>
      <div className={styles.tuto3Inner}>
        <div className={styles.ellipseParent}>
          <div className={styles.frameChild} />
          <b className={styles.b}>{prop}</b>
        </div>
      </div>
      <div className={styles.frameParent}>
        <div className={styles.titreWrapper}>
          <b className={styles.titre}>Titre</b>
        </div>
        <b className={styles.dcouvrezNotreStartup}>
          Découvrez notre startup dédiée à la robotique, où la science devient
          ludique et accessible à tous !
        </b>
      </div>
      <div className={styles.ctaWrapper}>
        <div className={styles.cta}>
          <div className={styles.ctaChild} />
          <b className={styles.plusDeDetails}>Plus de details</b>
        </div>
      </div>
    </div>
  );
};

export default Tuto1;
