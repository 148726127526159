import styles from "./FrameComponent.module.css";

const FrameComponent = () => {
  return (
    <section className={styles.produitsInner}>
      <div className={styles.produit1Parent}>
        <div className={styles.produit1}>
          <div className={styles.produit1Inner}>
            <div className={styles.tlphoneParent}>
              <b className={styles.tlphone}>Téléphone</b>
              <div className={styles.iphoneIcon}>
                <b className={styles.iphone}>Iphone</b>
              </div>
            </div>
          </div>
          <img
            className={styles.phone1Icon}
            loading="lazy"
            alt=""
            src="/phone-1@2x.png"
          />
        </div>
        <div className={styles.produit2Wrapper}>
          <div className={styles.produit2}>
            <div className={styles.descriptionWrapper}>
              <div className={styles.description}>
                <b className={styles.montage}>Montage</b>
                <div className={styles.arduinoHolder}>
                  <b className={styles.pose}>Pose</b>
                </div>
              </div>
            </div>
            <img
              className={styles.videoSupport1}
              loading="lazy"
              alt=""
              src="/video-support-1@2x.png"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent;
