import Tuto1 from "./Tuto1";
import styles from "./FrameComponent1.module.css";

const FrameComponent1 = () => {
  return (
    <div className={styles.frameParent}>
      <div className={styles.tuto4Wrapper}>
        <div className={styles.tuto4}>
          <div className={styles.tuto4Inner}>
            <div className={styles.ellipseParent}>
              <div className={styles.frameChild} />
              <b className={styles.b}>04</b>
            </div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.titreWrapper}>
              <b className={styles.titre}>Titre</b>
            </div>
            <b className={styles.dcouvrezNotreStartup}>
              Découvrez notre startup dédiée à la robotique, où la science
              devient ludique et accessible à tous !
            </b>
          </div>
          <div className={styles.ctaWrapper}>
            <div className={styles.cta}>
              <div className={styles.ctaChild} />
              <b className={styles.plusDeDetails}>Plus de details</b>
            </div>
          </div>
          <img className={styles.tuto4Child} alt="" />
        </div>
      </div>
      <div className={styles.tuto5Wrapper}>
        <Tuto1 prop="05" propWidth="unset" propAlignSelf="stretch" />
      </div>
      <div className={styles.tuto6}>
        <div className={styles.tuto6Inner}>
          <div className={styles.ellipseGroup}>
            <div className={styles.frameItem} />
            <b className={styles.b1}>06</b>
          </div>
        </div>
        <div className={styles.frameContainer}>
          <div className={styles.titreContainer}>
            <b className={styles.titre1}>Titre</b>
          </div>
          <b className={styles.dcouvrezNotreStartup1}>
            Découvrez notre startup dédiée à la robotique, où la science devient
            ludique et accessible à tous !
          </b>
        </div>
        <div className={styles.ctaContainer}>
          <div className={styles.cta1}>
            <div className={styles.ctaItem} />
            <b className={styles.plusDeDetails1}>Plus de details</b>
          </div>
        </div>
        <div className={styles.tuto6Child} />
      </div>
    </div>
  );
};

export default FrameComponent1;
