import Tuto1 from "./Tuto1";
import styles from "./FrameComponent2.module.css";

const FrameComponent2 = () => {
  return (
    <div className={styles.frameParent}>
      <div className={styles.tuto1Wrapper}>
        <div className={styles.tuto1}>
          <div className={styles.tuto1Child} />
          <b className={styles.b}>01</b>
          <div className={styles.titreParent}>
            <b className={styles.titre}>Titre</b>
            <b className={styles.dcouvrezNotreStartup}>
              Découvrez notre startup dédiée à la robotique, où la science
              devient ludique et accessible à tous !
            </b>
          </div>
          <div className={styles.verticalDivider} />
          <div className={styles.ctaWrapper}>
            <div className={styles.cta}>
              <div className={styles.ctaChild} />
              <b className={styles.plusDeDetails}>Plus de details</b>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.tuto2}>
        <div className={styles.tuto2Inner}>
          <div className={styles.ellipseParent}>
            <div className={styles.frameChild} />
            <b className={styles.b1}>02</b>
          </div>
        </div>
        <div className={styles.frameGroup}>
          <div className={styles.titreWrapper}>
            <b className={styles.titre1}>Titre</b>
          </div>
          <b className={styles.dcouvrezNotreStartup1}>
            Découvrez notre startup dédiée à la robotique, où la science devient
            ludique et accessible à tous !
          </b>
        </div>
        <div className={styles.ctaContainer}>
          <div className={styles.cta1}>
            <div className={styles.ctaItem} />
            <b className={styles.plusDeDetails1}>Plus de details</b>
          </div>
        </div>
        <div className={styles.tuto2Child} />
      </div>
      <Tuto1 prop="03" />
    </div>
  );
};

export default FrameComponent2;
