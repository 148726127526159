import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Splash.module.css";
import Tuto from "../pages/Tuto";
import Produits from "../pages/Produits";
import Galeries from "./Galeries";
import Contacts from "./Contacts";
import Projets from "../pages/Projets";

const Splash = () => {
  const navigate = useNavigate();

  const onSplashContainerClick = useCallback(() => {
    navigate("/tuto");
  }, [navigate]);

  const onTutorielTextClick = useCallback(() => {
    navigate("/tuto");
  }, [navigate]);

  const onProductsTextClick = useCallback(() => {
    navigate("/produits");
  }, [navigate]);

  const onProjectsTextClick = useCallback(() => {
    navigate("/projets");
  }, [navigate]);

  const onRectangleClick = useCallback(() => {
    navigate("/tuto");
  }, [navigate]);

  return (
    <div>
    <div className={styles.splash} onClick={onSplashContainerClick}>
      <img className={styles.backgroundIcon} alt="" src="/background@2x.png" />
      <div className={styles.splashInner}>
        <div className={styles.frameParent}>
          <div className={styles.frameWrapper}>
            <div className={styles.ellipseParent}>
              <div className={styles.frameChild} />
              <h3 className={styles.a}>a</h3>
            </div>
          </div>
          <div className={styles.tutorielParent}>
            <div className={styles.tutoriel} onClick={onTutorielTextClick}>
              Tutoriel
            </div>
            <div className={styles.productsLabel}>
              <div className={styles.products} onClick={onProductsTextClick}>
                Products
              </div>
            </div>
            <div className={styles.projects} onClick={onProjectsTextClick}>
              Projects
            </div>
          </div>
          <div className={styles.frameContainer}>
            <div className={styles.rectangleParent}>
              <div className={styles.frameItem} />
              <b className={styles.visitezMalicksEyes}>Visitez Malick’s eyes</b>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.labelBWrapper}>
        <div className={styles.labelB}>
          <div className={styles.annahWrapper}>
            <h1 className={styles.annah}>Annah</h1>
          </div>
          <div className={styles.roboticallyLabel}>
            <h1 className={styles.robotiquement}>Robotiquement</h1>
            <div className={styles.votreWrapper}>
              <h1 className={styles.votre}>votre.</h1>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.dcouvrezNotreStartupDdieParent}>
        <div className={styles.dcouvrezNotreStartup}>
          Découvrez notre startup dédiée à la robotique, où la science devient
          ludique et accessible à tous ! Transformons l'éducation avec les
          technologies de demain.
        </div>
        <div className={styles.goDownWrapper}>
          <div className={styles.goDown}>
            <div className={styles.goDownChild} onClick={onRectangleClick} />
            <b className={styles.decouvrezNosActivitsContainer}>
              <p className={styles.decouvrez}>{`Decouvrez `}</p>
              <p className={styles.nosActivits}>Nos activités</p>
            </b>
          </div>
        </div>
      </div>
    </div>
    <Tuto/>
    <Produits/>
    <Projets/>
    <Galeries/>
    <Contacts/>
    </div>
  );
};

export default Splash;
