import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Galeries.module.css";

const Galeries = () => {
  const navigate = useNavigate();

  const onGaleriesContainerClick = useCallback(() => {
    navigate("/contacts");
  }, [navigate]);

  return (
    <div className={styles.galeries} onClick={onGaleriesContainerClick}>
      <main className={styles.back}>
        <div className={styles.left}>
          <b className={styles.voirLesPhotos}>Voir les photos</b>
          <div className={styles.rectangleParent}>
            <img
              className={styles.frameChild}
              loading="lazy"
              alt=""
              src="/rectangle-18@2x.png"
            />
            <img
              className={styles.frameItem}
              alt=""
              src="/rectangle-19@2x.png"
            />
          </div>
          <div className={styles.rightColumnWrapper}>
            <img
              className={styles.rightColumnIcon}
              loading="lazy"
              alt=""
              src="/rectangle-20@2x.png"
            />
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.voirLesPhotosWrapper}>
            <b className={styles.voirLesPhotos1}>Voir les photos</b>
          </div>
          <div className={styles.rightInner}>
            <div className={styles.frameParent}>
              <div className={styles.moreImagesContainerWrapper}>
                <img
                  className={styles.moreImagesContainer}
                  loading="lazy"
                  alt=""
                  src="/rectangle-21@2x.png"
                />
              </div>
              <img
                className={styles.frameInner}
                loading="lazy"
                alt=""
                src="/rectangle-22@2x.png"
              />
            </div>
          </div>
          <img
            className={styles.moreImagesContainer1}
            loading="lazy"
            alt=""
            src="/rectangle-23@2x.png"
          />
        </div>
        <section className={styles.front}>
          <div className={styles.galleryOfImages}>
            <div className={styles.rightThumbnails}>
              <b className={styles.galerisParcDimages}>
                Galeris: parc d’images
              </b>
              <div className={styles.rectangleGroup}>
                <img
                  className={styles.rectangleIcon}
                  loading="lazy"
                  alt=""
                  src="/rectangle-14@2x.png"
                />
                <img
                  className={styles.frameChild1}
                  loading="lazy"
                  alt=""
                  src="/rectangle-15@2x.png"
                />
              </div>
            </div>
            <div className={styles.galleryOfImagesInner}>
              <img
                className={styles.frameChild2}
                alt=""
                src="/rectangle-17@2x.png"
              />
            </div>
          </div>
          <div className={styles.frameGroup}>
            <div className={styles.rectangleContainer}>
              <img
                className={styles.frameChild3}
                loading="lazy"
                alt=""
                src="/rectangle-19-1@2x.png"
              />
              <img
                className={styles.frameChild4}
                loading="lazy"
                alt=""
                src="/rectangle-18-1@2x.png"
              />
            </div>
            <img
              className={styles.frameChild5}
              alt=""
              src="/rectangle-16@2x.png"
            />
          </div>
        </section>
      </main>
    </div>
  );
};

export default Galeries;
