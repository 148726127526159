import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import FrameComponent from "../components/FrameComponent";
import styles from "./Produits.module.css";

const Produits = () => {
  const navigate = useNavigate();

  const onProduitsContainerClick = useCallback(() => {
    navigate("/projets");
  }, [navigate]);

  return (
    <div className={styles.produits} onClick={onProduitsContainerClick}>
      <FrameComponent />
      <section className={styles.productBoxCParent}>
        <div className={styles.productBoxC}>
          <div className={styles.produit3}>
            <img
              className={styles.arduino1Icon}
              alt=""
              src="/arduino-1@2x.png"
            />
            <div className={styles.description}>
              <b className={styles.electronique}>Electronique</b>
              <div className={styles.headphoneIcon}>
                <b className={styles.arduino}>Arduino</b>
              </div>
            </div>
          </div>
          <div className={styles.produit5Wrapper}>
            <div className={styles.produit5}>
              <div className={styles.bureauName}>
                <b className={styles.informatique}>Informatique</b>
              </div>
              <div className={styles.laptopofficeContainer}>
                <b className={styles.macBook}>Mac book</b>
                <img
                  className={styles.mackBook1}
                  loading="lazy"
                  alt=""
                  src="/mack-book-1@2x.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.produit4Parent}>
          <div className={styles.produit4}>
            <div className={styles.informatiqueParent}>
              <b className={styles.informatique1}>Informatique</b>
              <div className={styles.bureauWrapper}>
                <b className={styles.bureau}>Bureau</b>
              </div>
            </div>
            <img
              className={styles.laptopOffice1}
              loading="lazy"
              alt=""
              src="/laptop-office-1@2x.png"
            />
          </div>
          <div className={styles.produit6Wrapper}>
            <div className={styles.produit6}>
              <div className={styles.casqueName}>
                <b className={styles.divers}>Divers</b>
                <div className={styles.casqueWrapper}>
                  <b className={styles.casque}>Casque</b>
                </div>
              </div>
              <img
                className={styles.headphones1Icon}
                loading="lazy"
                alt=""
                src="/headphones-1@2x.png"
              />
            </div>
          </div>
        </div>
      </section>
      <div className={styles.ctaName}>
        <div className={styles.cta}>
          <div className={styles.ctaChild} />
          <b className={styles.voirDePlus}>Voir de plus que j’ai achété</b>
        </div>
      </div>
    </div>
  );
};

export default Produits;
