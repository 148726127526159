import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Tuto from "./pages/Tuto";
import Projets from "./pages/Projets";
import Produits from "./pages/Produits";
import Splash from "./components/Splash";
import Galeries from "./components/Galeries";
import Contacts from "./components/Contacts";


function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/projets":
        title = "";
        metaDescription = "";
        break;
      case "/produits":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" index={true} element={<Splash />} />
      <Route path="/tuto" element={<Tuto />} />
      <Route path="/projets" element={<Projets />} />
      <Route path="/produits" element={<Produits />} />
      <Route path="/galeries" element={<Galeries />} />
      <Route path="/contacts" element={<Contacts/>} />
    </Routes>
  );
}
export default App;
